import { childRoutes as newRoutes } from './news/news.routing.module';
import { childRoutes as aboutRoutes } from './about-us/about-us.routing.module';
import { childRoutes as doctorsRoutes } from './doctors/doctors.routing.module';
import { childRoutes as vacanciesRoutes } from './vacancies/vacancies.routing.module';
import { childRoutes as workshopsRoutes } from './workshops/workshops.routing.module';
import { childRoutes as branchesRoutes } from './branches/branches.routing.module';
import { childRoutes as servicesRoutes } from './services/services-routing.module';
import { childRoutes as priceListRoutes } from './price-list/price-list.routing.module';
import { childRoutes as attachmentsRoutes } from './attachments/attachments.routing.module';

import { ppRoutes } from './personal-packages/personal-packages.routing.module';
import { pagesRoutes } from './custom-pages/custom-pages.routing.module';
import { Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';

import { Paths } from '../shared';
import { FillSurveyComponent } from './personal-data/appointments/fill-survey/fill-survey.component';
import { environment } from '@env/environment';
import { AuthGuard } from '../core';
import { SurveyCompletedComponent } from './personal-data/survey-completed/survey-completed.component';
import { Map3dComponent } from './3d-map/3d-map.component';

export const routes: Routes = [
  {
    path: '',

    children: [
      {
        path: '',
        component: DashboardComponent,
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: Paths.News,
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
        data: { routes: newRoutes },
      },
      {
        path: Paths.MyFamilies,
        loadChildren: () => import('./families/families.module').then(m => m.FamiliesModule),
        data: { routes: newRoutes },
      },
      {
        path: Paths.Help,
        loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
        data: { routes: aboutRoutes },
      },

      {
        path: Paths.Doctors,
        loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule),
        data: { routes: doctorsRoutes },
      },
      {
        path: Paths.Vacancies,
        loadChildren: () => import('./vacancies/vacancies.module').then(m => m.VacanciesModule),
        data: { routes: vacanciesRoutes },
      },
      {
        path: Paths.Seminars,
        loadChildren: () => import('./workshops/workshops.module').then(m => m.WorkshopsModule),
        data: { routes: workshopsRoutes },
      },
      {
        path: Paths.Services,
        loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
        data: { routes: servicesRoutes },
      },
      {
        path: Paths.PriceList,
        loadChildren: () => import('./price-list/price-list.module').then(m => m.PriceListModule),
        data: { routes: priceListRoutes },
      },
      {
        path: Paths.Attachments,
        loadChildren: () => import('./attachments/attachments.module').then(m => m.AttachmentsModule),
        data: { routes: attachmentsRoutes },
      },

      {
        path: Paths.Branches,
        loadChildren: () => import('./branches/branches.module').then(m => m.BranchesModule),
        data: { routes: branchesRoutes },
      },
      {
        path: Paths.PersonalPackages,
        loadChildren: () => import('./personal-packages/personal-packages.module').then(m => m.PersonalPackagesModule),
        data: { routes: ppRoutes },
      },
      { path: '3d-map', component: Map3dComponent },
      { path: 'survey/:id', component: FillSurveyComponent, data: { public: true } },
      { path: 'survey-completed', component: SurveyCompletedComponent, data: { public: true } },

      {
        path: Paths.Pages,
        loadChildren: () => import('./custom-pages/custom-pages.module').then(m => m.CustomPagesModule),
        data: { routes: pagesRoutes },
      },

      { path: '403' },
      { path: '404' },
      { path: '500' },
    ],
  },
  { path: '**', redirectTo: '/404' },
];
