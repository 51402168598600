import { Component, ChangeDetectionStrategy, OnInit, HostListener, Input, SecurityContext, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '@core';
import { CompanyInfoI, GlobalSearchI, GlobalSearchTypes, User } from '@shared';
import { GlobalService } from '@shared/services/global.service';
import { BehaviorSubject, Observable, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';
import { headerLinks } from './header-links';
import { SettingsService } from '@s';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '@front/app/base-component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  links = headerLinks;
  companyInfo$: BehaviorSubject<CompanyInfoI>;
  user$: Observable<User | null>;
  isNavbarFixed = false;
  searchText$ = new BehaviorSubject<string>('');

  searchResult$: Observable<GlobalSearchI[]>;

  selectLoading$ = new BehaviorSubject<boolean>(true);

  @ViewChild('searchInput') searchInput!: ElementRef;

  constructor(
    private readonly srv: GlobalService,
    private readonly auth: AuthService,
    public readonly settings: SettingsService,
    private readonly router: Router,
    private _sanitizer: DomSanitizer
  ) {
    super();
    this.user$ = this.auth.user();
    this.companyInfo$ = this.srv.companyInfo$;

    this.searchResult$ = this.searchText$.pipe(
      debounceTime(300),
      switchMap(searchText => {
        return searchText.length > 2
          ? this.srv.globalSearch(searchText).pipe(
              map(i =>
                i.map(item => {
                  return {
                    ...item,
                    title: this.stripTags(item.title) || '',
                    description: this.stripTags(item.description) || '',
                  };
                })
              ),
              tap(() => this.selectLoading$.next(false))
            )
          : of([]).pipe(tap(() => this.selectLoading$.next(false)));
      })
    );

    this.router.events
      .pipe(
        this.takeUntilDestroy(),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.searchInput.nativeElement.value = '';
      });
  }

  ChangeSearchText(event: string): void {
    this.selectLoading$.next(true);
    this.searchText$.next(event);
  }

  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  selectSearchItem(event: GlobalSearchI) {
    switch (event.path) {
      case GlobalSearchTypes.services:
        this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + event.slug + '/price']);
        break;

      case GlobalSearchTypes.doctors:
        this.router.navigate(['/' + this.settings.getLanguage() + '/doctors/' + event.slug]);
        break;

      case GlobalSearchTypes.specializations:
        if (event.type === 'personalPackage') {
          this.router.navigate(['/' + this.settings.getLanguage() + '/personal-packages/' + event.slug]);
        } else {
          this.router.navigate(['/' + this.settings.getLanguage() + '/services/' + event.slug]);
        }
        break;

      case GlobalSearchTypes.news:
        this.router.navigate(['/' + this.settings.getLanguage() + '/news/' + event.slug]);
        break;

      case GlobalSearchTypes.seminars:
        this.router.navigate(['/' + this.settings.getLanguage() + '/seminars/' + event.slug]);
        break;

      case GlobalSearchTypes.vacancies:
        this.router.navigate(['/' + this.settings.getLanguage() + '/vacancies/' + event.slug]);
        break;

      // case GlobalSearchTypes.companyDescription:
      //   this.router.navigate(['/' + this.settings.getLanguage() + '/help/description']);
      //   break;

      case GlobalSearchTypes.faqs:
        this.router.navigate(['/' + this.settings.getLanguage() + '/help/frequently-asked-questions']);
        break;
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.scrollY > 73) {
      this.isNavbarFixed = true;
    } else {
      this.isNavbarFixed = false;
    }
  }

  logout(): void {
    this.auth.logout().subscribe();
  }

  ngOnInit() {}
}
