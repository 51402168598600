import { Nullable } from ".";

export interface FamiliesI {
    coverPhoto: Nullable<string>;
    photoPath: string;
    id: number;
    myFamilyDate: string;
    slug: string;
    title: string;
    description: string;
  }
  export interface FamiliesDataI {
    id: number;
    description: string;
    title: string;
    coverPhoto: Nullable<string>;
    photoPath: string;
    relatedFamilies: FamiliesI[];
    myFamilyDate: string;
  }

  export enum FamiliesShareType {
    facebook = 'facebook',
    twitter = 'twitter',
    linkedin = 'linkedin',
    share = 'share',
  }
