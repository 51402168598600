import { Injectable } from '@angular/core';
import { NewsDataI, NewsI, PaginationDto, PaginationI } from '@shared/interfaces';
import * as moment from 'moment';
import { map, Observable, of } from 'rxjs';
import { NewsApiService } from '../api/news.api.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private readonly newsSrv: NewsApiService) {}

  getNewsList(searchOptions: PaginationI): Observable<PaginationDto<NewsI>> {
    return this.newsSrv.getNewsList(searchOptions).pipe(
      map(d => {
        return {
          list:
            d?.data.map(i => ({
              ...i,
              newsDate: moment(i.newsDate).format('MMMM DD, YYYY'),
            })) || [],
          total: d.total || 0,
        };
      })
    );
  }

  getNewsBySlug(slug: string): Observable<NewsDataI | undefined> {
    return this.newsSrv.getNewsBySlug(slug).pipe(
      map(
        d =>
          d?.data && {
            ...d.data,
            newsDate: moment(d.data.newsDate).format('MMMM DD, YYYY'),
          }
      ),
      switchMap(news =>
        news
          ? this.newsSrv.getNewsRelatedById(news.id).pipe(
              map(related => {
                return {
                  ...news,
                  relatedNews: (related?.data || []).map(i => ({
                    ...i,
                    newsDate: moment(i.newsDate).format('MMMM DD, YYYY'),
                  })),
                };
              })
            )
          : of(undefined)
      )
    );
  }
}
